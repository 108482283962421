.c-solutions__item-img{
	width: 500px;
	height: 383px;
	float: left;
	@media ( max-width: @screen-md-max) {
		width: 400px;
		height: 300px;
	}
	@media ( max-width: @screen-sm-max) {
		float: none;
		margin-left: auto;
		margin-right: auto;
	}
	@media ( max-width: 500px) {
		width: 100%;
	}
	@media ( max-width: 400px) {
		height: 181px;
	}
}
.c-solutions__item.right-img{
	.c-solutions__item-img{
		float: right;
		@media ( max-width: @screen-sm-max) {
			float: none;
		}
	}
	.c-solutions__item-txt{
		padding-right: 46px;
		padding-left: 0;
		@media ( max-width: @screen-sm-max) {
			padding-right: 0;
		}
	}
}
.c-solutions__item{
	@media ( max-width: @screen-sm-max) {
		text-align: center;
	}
	&:not(:last-child){
		margin-bottom: 100px;
		@media ( max-width: @screen-sm-max) {
			margin-bottom: 50px;
		}
	}
}
.c-solutions{
	position: relative;
	padding-top: 90px;
	padding-bottom: 100px;
	@media ( max-width: @screen-sm-max) {
		padding-top: 50px;
		padding-bottom: 50px;
	}
}
.c-solutions__background{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	&:before{
		content: '';
		background-color: @green;
		opacity: 0.88;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 2;
	}
}
.c-solutions__content{
	position: relative;
	color: #fff;
	z-index: 3;
	h4{
		font-size: 36px;
		font-weight: 400;
		line-height: 48px;
		text-align: center;
		font-family: @font-family-serif;
	    margin-bottom: 87px;
	    @media ( max-width: @screen-sm-max) {
	    	font-size: 24px;
	    	margin-bottom: 50px;
	    }
	}
}
.c-solutions__item-txt{
	overflow: hidden;
    padding-left: 46px;
    margin-top: -5px;
    @media ( max-width: @screen-sm-max) {
    	margin-top: 45px;
    	padding-left: 0;
    }
	h6{
		font-size: 36px;
		font-weight: 400;
		font-family: @font-family-serif;
		@media ( max-width: @screen-sm-max) {
			font-size: 18px;
		}
	}
	p{
		font-size: 18px;
		font-weight: 300;
		line-height: 30px;
		margin-top: 37px;
		margin-bottom: 0;
		max-width: 570px;
		@media ( max-width: @screen-md-max) {
			font-size: 14px;
			line-height: 28px;
		}
		@media ( max-width: @screen-sm-max) {
			font-size: 12px;
			line-height: 24px;
			margin-left: auto;
			margin-right: auto;
		}
	}
}
.c-solutions__item-btns{
    margin-top: 58px;
    a:first-child{
	    margin-right: 30px;
	    @media ( max-width: @screen-sm-max) {
	    	margin-right: auto;
	    	margin-bottom: 27px;
	    }
    }
    a{
    	@media ( max-width: @screen-sm-max) {
    		display: block;
    	}
    }
}
.main_popup{
	.modal-dialog{
		width: 770px;
		@media ( max-width: @screen-xs-max) {
			width: 100%;
			margin: auto;
		}
	}
	.modal-content{
		padding-left: 70px;
		padding-right: 70px;
		padding-top: 85px;
		padding-bottom: 85px;
		box-shadow: none;
		border: none;
		@media ( max-width: @screen-sm-max) {
			padding: 60px 25px;
		}
	}
	h6{
		font-family: @font-family-serif;
		color: @green;
		font-size: 30px;
		text-transform: uppercase;
		margin-bottom: 40px;
		@media ( max-width: @screen-sm-max) {
			font-size: 24px;
		}
	}
	p{
		margin-bottom: 0;
		font-size: 18px;
		line-height: 30px;
		color: #7f7f7f;
		font-weight: 300;
		@media ( max-width: @screen-sm-max) {
			font-size: 14px;
		}
	}
	.main_popup__item{
		&:not(:last-child){
			margin-bottom: 60px;
			@media ( max-width: @screen-sm-max) {
				margin-bottom: 40px;
			}
		}
	}
}
.close-popup{
	width: 37px;
	height: 37px;
	background-color: @green;
	position: absolute;
	display: block;
	top: 0;
	right: 0;
	&:before, &:after{
		content: '';
		position: absolute;
		width: 18px;
		height: 1px;
		background-color: #fff;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
	}
	&:before{
		transform: rotate(45deg);
	}
	&:after{
		transform: rotate(-45deg);
	}
}
.modal-content p:not(:last-child) {
	margin-bottom: 60px;
}
.c-solutions__item-btns button {
	margin-right: 30px;
	@media ( max-width: @screen-sm-max) {
		margin: 0 auto;
		margin-bottom: 10px;
	}
}