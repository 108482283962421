/*----------  global changes START  ----------*/
*{
	outline: none !important;
}
html {
  height: 100%;
}
body {
	position: relative;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-height: 100%;
	&.over_hidden{
		overflow: hidden;
	}
}
section{
	position: relative;
	.anchor{
		position: absolute;
		top: -102px;
		left: 0;
		display: block;
		height: 1px;
		@media ( max-width: @screen-sm-max) {
			top: -53px;
		}
	}
}
img{
	max-width: 100%;
	height: auto;
}
.c-content{
	padding-top: 10px;
	padding-bottom: 10px;
}
.container{
	@media ( max-width: @screen-xs-max) {
		padding-left: 25px;
		padding-right: 25px;
	}
}
/*----------  global changes END    ----------*/


/*----------  colors classes  ----------*/


/*----------  bootstrap CHANGE HELPERS  ----------*/

/* typography */

// Headings
// -------------------------

h1,h2,h3,h4,.h1,.h2,.h3,.h4{
	// margin-top: 0;
	// margin-bottom: 20px;
	margin: 0;
	&.h_thin{
		font-weight: 400;
	}
}

h1,.h1{
	@media ( max-width: @screen-xs-max) {
		font-size: @font-size-h2;
	}
}
h2,.h2{
	@media ( max-width: @screen-xs-max) {
		font-size: @font-size-h3;
	}
}
h3, .h3{
	@media ( max-width: @screen-xs-max) {
		font-size: @font-size-h4;
		line-height: @line-height-base;
	}
}
h4, .h4{
	@media ( max-width: @screen-xs-max) {
		font-size: @font-size-h5;
	}
}
h5, .h5{
	line-height: 28px;
	@media ( max-width: @screen-xs-max) {
		font-size: @font-size-h6;
	}
}
h6, .h6{
	margin-top: 0;
	margin-bottom: 0;
	@media ( max-width: @screen-xs-max) {
		font-size: 13px;
		line-height: 15px;
	}
}
.h-sC{
	transition: background-color 300ms ease;
}

// Body text
// -------------------------
.text-small{
	font-size: 13px;
	line-height: 15px;
}
.text-large{
	font-size: @font-size-large;
}
p {
  margin: 0 0 20px;
}
a{
	color: inherit;
	text-decoration: none;
	&:hover{
		color: inherit;
		text-decoration: none;
	}
	&:focus{
		color: inherit;
		text-decoration: none;
	}
	&.active{
		color: inherit;
	}
	&.a_none{
		text-decoration: none;
	}
}
.h-overflow{
	overflow: hidden;
}
p{
	margin-bottom: 0;
}
/*----------  bootstrap CHANGE HELPERS  END  ----------*/

.l-page-wrapper {
	// min-height: 100vh;
	overflow: hidden;
}


.modal-content{
	border-radius: 0;
}