.c-team{
	padding-top: 97px;
	padding-bottom: 97px;
	background-color: #fafafa;
	@media ( max-width: @screen-sm-max) {
		padding: 40px 0;
	}
	h4{
		text-align: center;
		font-size: 36px;
		font-family: @font-family-serif;
		color: @d-blue;
		margin-bottom: 79px;
		@media ( max-width: @screen-sm-max) {
			font-size: 24px;
			margin-bottom: 40px;
		}
	}
}
.c-team__info-green{
	color: @green;
	font-size: 30px;
	font-weight: 400;
	line-height: 48px;
	text-align: right;
	font-family: @font-family-serif;
	padding-right: 13px;
	@media ( max-width: @screen-sm-max) {
		text-align: center;
		font-size: 18px;
		line-height: 30px;
		margin-bottom: 40px;
	}
}
.c-team__info-gray{
	color: @gray;
	font-size: 18px;
	font-weight: 300;
	line-height: 30px;
    padding-left: 2px;
    padding-top: 6px;
    @media ( max-width: @screen-sm-max) {
    	font-size: 12px;
    	line-height: 24px;
    	text-align: center;
    }
}
.c-team__info{
	margin-bottom: 85px;
	@media ( max-width: @screen-sm-max) {
		margin-bottom: 45px;
	}
}
.c-team__people-item-img{
	height: 289px;
	@media ( max-width: @screen-md-max) {
		height: 238px;
	}
	@media ( max-width: 430px) {
		height: 320px;
	}
}
.c-team__people-item-name{
	color: @green;
	font-family: @font-family-serif;
	font-size: 30px;
    margin: 36px 0 20px;
    @media ( max-width: @screen-sm-max) {
    	font-size: 24px;
    	margin: 23px 0;
    }
}
.c-team__people-item{
	text-align: center;
}
.c-team__people-item-contact{
	span{
		display: block;
		font-size: 18px;
		font-weight: 300;
		&:not(:last-child){
			margin-bottom: 6px;
		}
		@media ( max-width: @screen-sm-max) {
			font-size: 12px;
		}
	}
}
.c-team__people{
	[class*='col-']{
		@media ( max-width: 430px) {
			width: 100%;
		}
		&:not(:last-child){
			@media ( max-width: @screen-sm-max) {
				margin-bottom: 40px;
			}
		}
	}

}
.c-team__people > .row > div {
	@media ( min-width: @screen-sm-max) {
		margin-top: 50px; 
	}
}