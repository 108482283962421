.piilar__board{
	margin-top: 102px;
	padding-top: 103px;
	padding-bottom: 100px;
	background-color: #f3fffe;
	@media ( max-width: @screen-sm-max) {
		margin-top: 53px;
		background-color: #fff;
		padding: 0;
	}
	.container{
		@media ( max-width: @screen-sm-max) {
			padding: 0;
			width: 100%;
		}
	}
}
.piilar__board-content{
	height: 482px;
	background-color: #fff;
	box-shadow: 0 0 130px rgba(8, 68, 88, 0.2);
	@media ( max-width: @screen-sm-max) {
		height: auto;
	}
}
.btn-back{
	float: right;
	padding-top: 3px;
	.icon{
		width: 20px;
		height: 16px;
		color: #fff;
	}
}
.piilar__board-slider{
	height: 100%;
	transition: all 0.3s ease;
	&:not(.slick-initialized){
		opacity: 0;
	}
	.slick-list{
		height: 100%;
	}
	.slick-track{
		height: 100%;
	}
	.slick-dots{
		font-size: 0;
		padding-left: 0;
		list-style-type: none;
		margin-bottom: 0;
		position: absolute;
		bottom: 45px;
		left: 0;
		right: 0;
		margin: auto;
		text-align: center;
		@media ( max-width: @screen-xs-max) {
			bottom: 15px;
		}
		li{
			display: inline-block;
			vertical-align: middle;
			&:not(:last-child){
				margin-right: 14px;
			}
			&.slick-active{
				button{
					opacity: 1;
					width: 10px;
					height: 10px;
					@media ( max-width: @screen-xs-max) {
						width: 6px;
						height: 6px;
					}
				}
			}
			button{
				background-color: #fff;
				width: 8px;
				border-radius: 50%;
				opacity: 0.5;
				height: 8px;
				transition: all 0.3s ease;
				@media ( max-width: @screen-xs-max) {
					width: 5px;
					height: 5px;
				}
			}
		}
	}
	.slick-arrow{
		position: absolute;
		font-size: 0;
		width: 34px;
		height: 50px;
		background-color: @green;
		display: block;
		top: 0;
		bottom: 0;
		z-index: 1;
		margin: auto;
		transition: all 0.3s ease;
		@media ( max-width: @screen-xs-max) {
			display: none !important;
		}
		&:hover{
			background-color: (@green - 30%);
		}
		&:before{
			content: '';
			width: 15px;
			height: 15px;
			border-right: 1px solid #fff;
			border-bottom: 1px solid #fff;
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			margin: auto;
			
		}
	}
	.slick-prev{
		left: 0;
		&:before{
		    transform: rotate(135deg);
		    left: 8px;
		}
	}
	.slick-next{
		right: 0;
		&:before{
			transform: rotate(-45deg);
		    right: 8px;
		}
	}
}
.piilar__board-slider-item{
	height: 100%;
}
.piilar__board-slider-wrp{
	width: 65.9%;
	float: left;
	height: 100%;
	@media ( max-width: @screen-sm-max) {
		width: 100%;
		height: 482px;
	}
	@media ( max-width: @screen-xs-max) {
		height: 362px;
	}
	@media ( max-width: 550px) {
		height: 300px;
	}
	@media ( max-width: 400px) {
		height: 175px;
	}
}
.piilar__board-info{
	width: 34.1%;
	float: left;
    padding-top: 48px;
    padding-left: 46px;
    padding-right: 48px;
    @media ( max-width: @screen-sm-max) {
    	width: 100%;
    	padding-bottom: 50px;
    }
    @media ( max-width: @screen-xs-max) {
    	padding: 25px;
    	padding-bottom: 45px;
    }
}
.piilar__board-info-head{
	margin-bottom: 63px;
	@media ( max-width: @screen-xs-max) {
		margin-bottom: 38px;
	}
	h2{
		font-family: @font-family-serif;
		color: @green;
		font-size: 30px;
		font-weight: 400;
		text-transform: uppercase;
		margin-bottom: 6px;
		@media ( max-width: @screen-xs-max) {
			font-size: 24px;
		}
	}
	span{
		font-family: @font-family-serif;
		font-size: 14px;
		font-weight: 400;
		text-transform: uppercase;
	}
}
.table-item__key{
	color: @d-blue;
	font-size: 14px;
	font-weight: 600;
	float: left;
	width: 117px;
	@media ( max-width: @screen-xs-max) {
		font-size: 12px;
	}
}
.table-item__value{
	font-size: 14px;
	font-weight: 400;
	overflow: hidden;
	@media ( max-width: @screen-xs-max) {
		font-size: 12px;
	}
}
.piilar__board-table-item{
	border-bottom: 1px solid #d2d2d2;
	padding-bottom: 13px;
	&:not(:last-child){
	    margin-bottom: 18px;
	}
}
#pillar_map{
	height: 610px;
	width: 100%;
	@media ( max-width: @screen-xs-max) {
		height: 324px;
	}
}