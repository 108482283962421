.c-works__slider-img{
	height: 306px;
	width: 100%;
	@media ( max-width: @screen-md-max) {
		height: 236px;
	}
	@media ( max-width: @screen-sm-max) {
		width: 100%;
		height: 221px;
	}
}
.c-works__slider{
	transition: all 0.3s ease;
	&:not(.slick-initialized){
		opacity: 0;
	}
}
.c-works__slider-item{
	@media ( max-width: @screen-sm-max) {
		text-align: center;
	}
	h6{
		color: @green;
		font-size: 30px;
		font-weight: 400;
		font-family: @font-family-serif;
	    margin: 39px 0;
	    @media ( max-width: @screen-sm-max) {
	    	font-size: 21px;
	    	line-height: 24px;
	    	margin: 30px 0 20px;
	    }
	}
	p{
		margin-bottom: 0;
		color: @gray;
		font-size: 18px;
		line-height: 30px;
		font-weight: 300;
		@media ( max-width: @screen-sm-max) {
			font-size: 12px;
			line-height: 24px;
		}
	}
}
.slides-counter{
	text-align: center;
	font-family: @font-family-serif;
	font-size: 12px;
	color: @gray;
	margin-top: 28px;
	font-weight: 400;
	.slides-counter__current{
		font-size: 18px;
		color: @green;
	}
}
.c-works__slider-item-wrp{
	padding: 0 15px;
	position: relative;
	@media ( max-width: @screen-xs-max) {
		padding: 0 7px;
		opacity: 0.6;
		transition: all 0.3s ease;
		&.slick-current{
			opacity: 1;
		}
	}
}
.c-works__slider-wrp{
	margin: 0 -15px;
	@media ( max-width: @screen-sm-max) {
		margin: 0 -7px;
	}
	.slick-arrow{
		font-size: 0;
		width: 22px;
		height: 22px;
		position: absolute;
		top: 141px;
		margin: auto;
		transition: all 0.3s ease;
		@media ( max-width: @screen-md-max) {
			top: 100px;
			width: 15px;
			height: 15px;
		}
		&.slick-disabled{
			opacity: 0;
			pointer-events: none;
		}
		&:hover{
			&:before{
				border-color: @d-blue;
			}
		}
		&:before{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			border-right: 2px solid @green;
			border-bottom: 2px solid @green;
			transition: all 0.3s ease;
		}
	}
	.slick-prev{
		left: -47px;
		@media ( max-width: 1340px) {
			left: -27px;
		}
		@media ( max-width: @screen-md-max) {
			left: -15px;
		}
		&:before{
			transform: rotate(135deg)
		}
	}
	.slick-next{
		right: -47px;
		@media ( max-width: 1340px) {
			right: -27px;
		}
		@media ( max-width: @screen-md-max) {
			right: -15px;
		}
		&:before{
			transform: rotate(-45deg)
		}
	}
}
.c-works{
	padding-top: 94px;
	padding-bottom: 90px;
	@media ( max-width: @screen-sm-max) {
		padding: 45px 0;
	}
	.container{
		@media ( max-width: @screen-sm-max) {
			padding-left: 0;
			padding-right: 0;
		}
	}
	h4{
		font-family: @font-family-serif;
		color: @d-blue;
		font-size: 36px;
		text-align: center;
		font-weight: 400;
	    margin-bottom: 94px;
	    @media ( max-width: @screen-sm-max) {
	    	font-size: 24px;
	    	margin-bottom: 45px;
	    }
	}
}