header.sticky, header.not-main{
	background-color: #fff;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    @media ( max-width: @screen-sm-max) {
    	padding-top: 15px;
    	padding-bottom: 15px;
    	height: auto;
    }
	.c-header__logo{
		svg{
			.cls-1{
				fill: #0D8355;
			}
			.cls-2, .cls-3{
				fill: #1A1A18;
			}
		}
	}
	.c-header__nav{
		color: @d-blue;
		@media ( max-width: @screen-sm-max) {
			color: #fff;
		}
		li{
			a:hover{
				border-bottom-color: #084458;
			}
		}
		li.active {
			a {
				border-bottom-color: #084458;
			}
		}
	}
	.open-mob-menu{
		span{
			background-color: @green;
		}
	}
}
header.not-main{
	@media ( max-width: @screen-sm-max) {
		background-color: @green;
		.c-header__logo{
			svg{
				.cls-1{
					fill: #fff;
				}
				.cls-2, .cls-3{
					fill: #fff;
				}
			}
		}
		.open-mob-menu span{
			background-color: #fff;
		}
	}
}
header{
	transition: background-color 0.3s ease;
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
	padding-top: 37px;
	padding-bottom: 37px;
	@media ( max-width: @screen-sm-max) {
		padding-top: 23px;
		padding-bottom: 23px;
		height: 69px;
		transition: background-color 0.3s ease, height 0.3s ease;
		&.active{
			height: 100%;
		}
	}
}
.main-section{
	height: 100vh;
	position: relative;
}
.main-section__img{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	&:before{
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		display: block;
		opacity: 0.67;
		background-color: #00baad;
		z-index: 1;
	}
}
.main-section__content{
	position: relative;
	z-index: 6;
	color: #fff;
	font-family: @font-family-serif;
	height: 100%;
}
.c-header__logo{
	float: left;
	svg{
		width: 181px;
		height: 25px;
		color: #fff;
		transition: all 0.3s ease;
		.cls-1{
			fill: #fff;
		}
		.cls-2{
			fill: #fff;
		}
		.cls-3{
			fill: #fff;
		}
		@media ( max-width: @screen-sm-max) {
			width: 147px;
			height: 20px;
		}
	}
}
.c-header__nav{
	float: right;
	font-size: 18px;
	font-weight: 400;
	padding-top: 5px;
	@media ( max-width: @screen-sm-max) {
		float: none;
		position: absolute;
		background: #00baad;
		width: 100%;
		height: 100%;
		left: 0%;
		z-index: 5;
		top: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		transition: all 0.5s ease;
		opacity: 0;
		pointer-events: none;
		&.active{
			opacity: 1;
			pointer-events: auto;
		}
	}
	li{
	    margin-left: 96px;
    	letter-spacing: 0.6px;
    	@media ( max-width: @screen-sm-max) {
    		display: block;
    		margin-left: 0;
    		letter-spacing: 2px;
    		&:not(:last-child){
    			margin-bottom: 50px;
    		}
    	}
    	a{
    		transition: all 0.3s ease;
    		padding-bottom: 5px;
    		border-bottom: 2px solid transparent;
    		&:hover {
				border-bottom: 2px solid #fff;
			}
    	}

    	&.active{
    		a{
	    		transition: all 0.3s ease;
	    		border-bottom: 2px solid #fff;
	    		@media ( max-width: @screen-sm-max) {
	    			border-bottom: none;
	    		}
    		}
    	}
	}
}
.main-section__text{
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	// height: calc(~'100% - 208px');
	height: 100%;
	@media ( max-width: @screen-sm-max) {
		// height: calc(~'100% - 81px');
	}
	h1{
		max-width: 766px;
		margin-left: auto;
		margin-right: auto;
		color: white;
		font-size: 40px;
		font-weight: 400;
		line-height: 54px;
		@media ( max-width: @screen-sm-max) {
			font-size: 20px;
			line-height: 33px;
		}
	}
	a{
		color: @green;
		font-size: 20px;
		font-weight: 400;
		background-color: #fff;
		box-shadow: 0 0 143.4px 19.6px rgba(0, 62, 62, 0.35);
		display: inline-block;
		margin-top: 42px;
		padding: 0 54px;
		line-height: 62px;
		letter-spacing: 2px;
		transition: all 0.3s ease;
		@media ( max-width: @screen-sm-max) {
			font-size: 15px;
			line-height: 50px;
		}
		&:hover{
			color: #fff;
			background-color: @green;
			box-shadow: 0 0 143.4px 19.6px rgba(0, 62, 62, 0.35);
		}
	}
}
.open-mob-menu{
	float: right;
	span{
		display: block;
		width: 30px;
		height: 3px;
		background-color: #fff;
		margin-bottom: 5px;
		&:last-child{
			width: 22px;
			margin-bottom: 0;
		}
	}
}
.close-mob-menu{
	width: 30px;
	height: 30px;
	position: absolute;
	top: 15px;
	right: 27px;
	&:before, &:after{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 30px;
		height: 2px;
		background-color: #fff;
	}
	&:before{
		transform: rotate(45deg);
	}
	&:after{
		transform: rotate(-45deg);
	}
}