.c-advantages__item-head{
	.icon{
		color: #084458;
		float: left;
	}
	h6{
		color: @green;
		font-size: 30px;
		font-weight: 400;
		line-height: 30px;
		font-family: @font-family-serif;
		padding-left: 24px;
	    padding-top: 21px;
	    overflow: hidden;
	    @media ( max-width: @screen-md-max) {
	    	font-size: 24px;
	    	line-height: 24px;
	    	padding-top: 10px;
	    }
	}
	.icon-bill{
		width: 41px;
		height: 77px;
		@media ( max-width: @screen-md-max) {
			width: 31px;
			height: 58px;
		}
	}
	.icon-team{
		width: 77px;
		height: 70px;
		@media ( max-width: @screen-md-max) {
			width: 58px;
			height: 53px;
		}
	}
	.icon-sign{
		width: 73px;
		height: 77px;
		@media ( max-width: @screen-md-max) {
			width: 55px;
			height: 58px;
		}
	}
}
.c-advantages{
    padding-top: 100px;
    padding-bottom: 92px;
    @media ( max-width: @screen-sm-max) {
    	padding-top: 50px;
    	padding-bottom: 50px;
    }
	h4{
		color: #084458;
		font-size: 36px;
		font-weight: 400;
		text-align: center;
		text-transform: uppercase;
		font-family: @font-family-serif;
		margin-bottom: 67px;
		@media ( max-width: @screen-sm-max) {
			font-size: 24px;
			margin-bottom: 50px;
		}
	}
	[class*='col']{
		@media ( max-width: @screen-sm-max) {
			&:not(:last-child){
				margin-bottom: 50px;
			}
		}
	}
}
.c-advantages__item-body{
	color: @gray;
	font-size: 18px;
	font-weight: 300;
	line-height: 30px;
	margin-top: 36px;
	@media ( max-width: @screen-md-max) {
		font-size: 14px;
		line-height: 28px;
	}
	@media ( max-width: @screen-sm-max) {
		font-size: 12px;
		line-height: 24px;
	}
}