.font-face (@fontname, @fontfile, @fontweight: normal, @fontstyle: normal) {
	@font-face {
		font-family: @fontname;
		src: url('../fonts/@{fontfile}.eot');
		src: url('../fonts/@{fontfile}.eot?#iefix') format('embedded-opentype'),
			 url('../fonts/@{fontfile}.woff') format('woff'),
			 url('../fonts/@{fontfile}.ttf') format('truetype'),
			 url('../fonts/@{fontfile}.svg#@{fontname}') format('svg');
		font-weight: @fontweight;
		font-style: @fontstyle;
	}
}

.font-face ('rouble', 'rouble/rouble');

.rB{
	font-style: normal;
	font-weight: normal;
	display: inline-block;
	vertical-align: baseline;
	&:after{
		content: 'a';
		display: block;
		font-family: 'rouble';
	}
}