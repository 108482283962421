.c-footer{
	background-color: #084458;
	color: #fff;
	padding-top: 95px;
    padding-bottom: 45px;
    @media ( max-width: @screen-sm-max) {
    	text-align: center;
    	padding: 40px 0;
    }
}
.c-footer__contact-links{
	margin-top: 68px;
	@media ( max-width: @screen-sm-max) {
		margin-top: 45px;
	}
	.link-wrapper:not(:last-child) {
		margin-bottom: 34px;
	}
	a{
		display: inline-block;
		&:hover{
			span{
				border-bottom: 1px solid transparent;
			}
		}
		i{
			width: 24px;
			display: inline-block;
			vertical-align: middle;
			@media ( max-width: @screen-sm-max) {
				display: block;
				margin-bottom: 10px;
				margin-left: auto;
				margin-right: auto;
			}
		}
		span{
			display: inline-block;
			vertical-align: middle;
			margin-left: 15px;
			font-family: @font-family-serif;
			border-bottom: 1px solid #fff;
			transition: all 0.3s ease;
			font-size: 24px;
			@media ( max-width: @screen-sm-max) {
				font-size: 15px;
				margin-left: 0;
			}
		}
	}
	.icon{
		color: @green;
		@media ( max-width: @screen-sm-max) {
			margin-left: auto;
			margin-right: auto;
		}
	}
	.icon-location{
		width: 24px;
		height: 24px;
	}
	.icon-doc{
		width: 19px;
		height: 24px;
	}
	.icon-docs{
		width: 19px;
		height: 24px;
	}
}
.c-footer__contact{
	@media ( max-width: @screen-sm-max) {
		margin-bottom: 64px;
	}
	h6{
		font-size: 36px;
		font-family: @font-family-serif;
		margin-bottom: 42px;
		@media ( max-width: @screen-sm-max) {
			font-size: 24px;
			margin-bottom: 30px;
		}
	}
}
.c-footer__contact-adr{
	span{
		font-weight: 300;
		display: block;
		font-size: 18px;
		color: #dadada;
		@media ( max-width: @screen-sm-max) {
			font-size: 12px;
		}
		&:not(:last-child){
		    margin-bottom: 19px;
		  	@media ( max-width: @screen-sm-max) {
		  		margin-bottom: 15px;
		  	}
		}
	}
}
.c-footer__form-wrp{
	h6{
		font-size: 36px;
		font-family: @font-family-serif;
		margin-bottom: 46px;
		@media ( max-width: @screen-sm-max) {
			font-size: 24px;
			margin-bottom: 40px;
		}
	}
	label{
		display: block;
		width: 100%;
		&:not(:last-child){
			margin-bottom: 31px;
		}
	}
	input, textarea{
		width: 100%;
		background: none;
		border: none;
		border-bottom: 1px solid #fff;
	}
	input{
	    padding-left: 6px;
    	padding-bottom: 11px;
    	@media ( max-width: @screen-sm-max) {
		    padding-bottom: 9px;
    	}
	}
	textarea{
		resize: none;
		padding-left: 6px;
		padding-bottom: 70px;
		@media ( max-width: @screen-sm-max) {
			padding-bottom: 120px;
		}
	}
	::-webkit-input-placeholder{
		color: #fff;
		font-size: 18px;
		color: #cbcbcb;
		font-weight: 300;
		transition: all 0.3s ease;
		@media ( max-width: @screen-sm-max) {
			font-size: 12px;
		}
	}
	::-moz-placeholder{
		color: #fff;
		font-size: 18px;
		color: #cbcbcb;
		font-weight: 300;
		transition: all 0.3s ease;
		@media ( max-width: @screen-sm-max) {
			font-size: 12px;
		}
	}
	:-moz-placeholder{
		color: #fff;
		font-size: 18px;
		color: #cbcbcb;
		font-weight: 300;
		transition: all 0.3s ease;
		@media ( max-width: @screen-sm-max) {
			font-size: 12px;
		}
	}
	:-ms-input-placeholder{
		color: #fff;
		font-size: 18px;
		color: #cbcbcb;
		font-weight: 300;
		transition: all 0.3s ease;
		@media ( max-width: @screen-sm-max) {
			font-size: 12px;
		}
	}
	:focus::-webkit-input-placeholder {opacity: 0;}
	:focus::-moz-placeholder          {opacity: 0;}
	:focus:-moz-placeholder           {opacity: 0;}
	:focus:-ms-input-placeholder      {opacity: 0;}
}
.c-footer__created{
	font-size: 11px;
	color: #b8b8b8;
	float: right;
	font-weight: 300;
	margin-top: 100px;
	@media ( max-width: @screen-sm-max) {
		float: none;
		margin-top: 50px;
	}
	a{
		font-weight: 700;
		transition: all 0.3s ease;
		&:hover{
			color: #fff;
		}
	}
}
.contact__form-notification {
	color: @green;
	font-family: 'Abel', sans-serif;
	margin-top: 20px;
	font-size: 20px;
}
.contact__form-notification.error {
	color: #fe0042 !important;
}
input, textarea {
	border-radius: 0;
}
input.error, textarea.error {
	border-bottom: 1px solid #fe0042 !important;
}