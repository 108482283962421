button{
    display: inline-block;
    margin: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
}
.c-solutions__item {
    button{
        &:hover{
            background-color: #fff;
            color: @green;
        }
    }  
}
.c-team__info {
    margin-bottom: 50px !important;
}
.c-team__read-more{
    text-align: center;
    @media ( max-width: @screen-sm-max) {
        margin-bottom: 50px; 
    }
}
.but{
    display: inline-block;
    border: 1px solid #fff;
    color: #fff;
    font-family: @font-family-serif;
    line-height: 46px;
    text-align: center;
    position: relative;
    transition: all 0.3s ease;
    padding: 0 45px;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 2px;
    @media ( max-width: @screen-sm-max) {
        font-size: 15px;
        width: 215px;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
    &_reverse{
        border: 1px solid #084458;
        color: #084458;
        &:hover{
            background-color: #084458;;
            color: #fff;
        }
    }
    &_white{
        background-color: #fff;
        color: @green;
        &:hover{
            color: @green;
            background: #084458;
            border-color: #084458;
        }
    }
    &_green{
        background-color: @green;
        color: #fff;
        border-color: @green;
        margin-top: 19px;
        padding: 0 78px;
        line-height: 60px;
        font-size: 20px;
        @media ( max-width: @screen-sm-max) {
            font-size: 15px;
            line-height: 57px;
            display: block;
            max-width: 270px;
            padding: 0 15px;
            margin-left: auto;
            margin-right: auto;
        }
        &:hover{
            background-color: (@green - 30%);
            border-color: (@green - 30%);
        }
    }
}