.c-map-sidebar{
	width: 248px;
	background-color: #093b4b;
	color: #849da5;
	height: 100vh;
	position: relative;
	z-index: 3;
	@media ( max-width: @screen-sm-max) {
		height: 100vh;
		width: 100%;
		pointer-events: none;
		background-color: transparent;
	}
}
.c-map-sidebar__heading{
	background-color: @green;
	padding: 18px 30px;
	@media ( max-width: @screen-sm-max) {
		padding: 15px 25px;
	}
	svg{
		width: 181px;
		height: 25px;
		margin-left: auto;
		margin-right: auto;
		.cls-1, .cls-2, .cls-3{
			fill: #fff;
		}
		@media ( max-width: @screen-sm-max) {
			width: 147px;
			height: 20px;
		}
	}
}
.c-map-sidebar__filter{
	@media ( min-width: @screen-md-min) {
		&.collapse{
			display: block;
			height: 100% !important;
		}
	}
	@media ( max-width: @screen-sm-max) {
		overflow: auto;
	    height: calc(~'100vh - 105px');
		pointer-events: all;
	}
}
.c-map-sidebar__content{
	height: calc(~'100vh - 64px');
	@media ( max-width: @screen-sm-max) {
		height: auto;
	}
}
.c-map-sidebar__ttl{
	font-size: 18px;
	text-transform: uppercase;
	color: @green;
	font-family: @font-family-serif;
    padding: 22px 33px;
	border-bottom: 1px solid #0A4558;
	@media ( max-width: @screen-sm-max) {
		padding: 15px 25px;
		background-color: #093b4b;
		pointer-events: all;
		position: relative;
		&:before{
			content: '';
			width: 10px;
			height: 10px;
			border-right: 1px solid @green;
			border-bottom: 1px solid @green;
			display: block;
			transform: rotate(-45deg);
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto;
			right: 25px;
			transition: all 0.3s ease;
		}
		&[aria-expanded="true"]{
			&:before{
				transform: rotate(45deg);
			}
		}
		&[aria-expanded="false"]{
			&:before{
				transform: rotate(-45deg);
			}
		}
	}
}
.c-map-sidebar__filter-item{
	position: relative;
	@media ( max-width: @screen-sm-max) {
		pointer-events: all;
		background-color: #093b4b;
	}
	ul{
		li{
			&:first-child{
				display: none;
			}
		}
	}
	&[data-marker-color*="color-"]{
		.color-dot{
			width: 11px;
			height: 11px;
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			right: 35px;
			border-radius: 50%;
			display: block;
			margin: auto;
			background-color: transparent;
			z-index: 2;
		}

	}
	&[data-marker-color="color-green"]{
		.color-dot{
			background-color: @green;
		}

	}
	&[data-marker-color="color-blue"]{
		.color-dot{
			background-color: #00ADE4;
		}
	}
	
}
.c-map-sidebar__filter-item-btn{
	cursor: pointer;
	border-bottom: 1px solid #0A4558;
	position: relative;
	height: 46px;
	transition: all 0.3s ease;
	// &.active{
	// 	background-color: #062b37;
	// }
	&:hover{
		background-color: #062b37;
		label{
			i{
				border-color: #fff;
			}
			span{
				color: #fff;
			}
		}
	}
	&[data-toggle="collapse"]{
		&:before{
			content: '';
			width: 10px;
			height: 10px;
			display: block;
			border-right: 1px solid #6B858D;
			border-bottom: 1px solid #6B858D;
			transform: rotate(45deg);
			right: 36px;
			top: -3px;
			bottom: 0;
			margin: auto;
		    position: absolute;
		    transition: all 0.3s ease;
		}
	}
	&[aria-expanded="true"]{
		&:before{
			transform: rotate(45deg);
			top: -3px;
		}
	}
	&[aria-expanded="false"]{
		&:before{
			top: 0;
			transform: rotate(-45deg);
		}
	}
	label{
		position: absolute;
		width: 100%;
		height: 100%;
		display: block;
		left: 0;
		top: 0;
		padding: 14px 32px;
	}
	input{
		opacity: 0;
		visibility: hidden;
		position: absolute;
		top: 0;
		left: 0;
		&:checked ~ i{
			border-color: @green;
			&:before{
				opacity: 1;
			}
		}
		&:checked ~ span{
			color: #fff;
			transition: all 0.3s ease;
		}
	}
	i{
		width: 17px;
		height: 17px;
		border: 1px solid #849DA5;
		display: inline-block;
		position: relative;
		vertical-align: middle;
		transition: all 0.3s ease;
		&:before{
			content: '';
			width: 5px;
			height: 9px;
			border-right: 1px solid @green;
			border-bottom: 1px solid @green;
			transform: rotate(45deg);
			display: block;
			position: absolute;
			top: 0;
			bottom: 3px;
			left: 0;
			right: 0;
			margin: auto;
			opacity: 0;
			transition: all 0.3s ease;
		}
	}
	span{
		font-family: @font-family-serif;
		font-size: 16px;
		font-weight: 400;
		display: inline-block;
		vertical-align: middle;
		padding-left: 14px;
		transition: all 0.3s ease;
	}
}
.c-map-sidebar__filter-item-drop{
	ul{
		list-style-type: none;
		margin-bottom: 0;
	    padding: 22px 31px;
	    @media ( max-width: @screen-sm-max) {
	    	background-color: #0b495d;
	    }
	}
	li{
		&:not(:last-child){
			margin-bottom: 13px;
		}
	}
	a{
		transition: all 0.3s ease;
		&:hover, &.active{
			color: #fff;
		}
		div{
			font-size: 14px;
			text-transform: uppercase;
		}
		span{
			font-size: 9px;
			display: block;
			margin-top: 3px;
			text-transform: uppercase;
		}
	}
}
.mCSB_scrollTools{
	@media ( max-width: @screen-sm-max) {
		display: none !important;
	}
}
.mCSB_scrollTools .mCSB_draggerRail{
	width: 19px;
	border-radius: 0;
	background-color: #0E546A;
}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
	width: 100%;
	border-radius: 0;
	background-color: #083442 !important;
}
.mCSB_scrollTools .mCSB_buttonUp, .mCSB_scrollTools .mCSB_buttonDown, .mCSB_scrollTools .mCSB_buttonLeft, .mCSB_scrollTools .mCSB_buttonRight{
	background: #0E546A;
	opacity: 1;
}
.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar{
	background-color: #083442;
}
.mCSB_buttonUp{
	&:before{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: block;
		margin: auto;
		width: 0;
		height: 0;
		border-left: 4px solid transparent;
		border-right: 4px solid transparent;
		border-bottom: 6px solid #083442;
	}
}
.mCSB_buttonDown{
	&:before{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: block;
		margin: auto;
		width: 0;
		height: 0;
		border-left: 4px solid transparent;
		border-right: 4px solid transparent;
		border-top: 6px solid #083442;
	}
}
.mCSB_inside > .mCSB_container{
	margin-right: 0;
}
.c-map-map{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}
#big_map{
	width: 100%;
	height: 100%;
}
.c-map-page{
	position: relative;
	height: 100vh;
}
.c-map__popup-img{
	height: 317px;
	@media ( max-width: @screen-sm-max) {
		height: 230px;
	}
}
.c-map__popup{
	width: 566px;
	position: relative;
	@media ( max-width: @screen-sm-max) {
		height: 100vh;
		width: 100%;
	}
	&:before{
		content: '';
		width: 0px; 
		height: 0px; 
		border-right: 10px solid transparent; 
		border-left: 10px solid transparent; 
		border-top: 19px solid #fff; 
		position: absolute; 
		left: 0;
		right: 0;
		margin: auto;
		bottom: -19px;
	}
}
.c-map__popup-table{
	float: left;
}
.c-map__popup-name{
	float: right;
	text-align: right;
	position: absolute;
	right: 34px;
	max-width: 200px;
	@media ( max-width: 991px) {
		position: static;
	}
	@media ( max-width: @screen-sm-max) {
		text-align: left;
		float: none;
		margin-bottom: 25px;
	}
	b{
		font-family: @font-family-serif;
		font-size: 26px;
		font-weight: 400;
		color: @green;
		margin-bottom: 5px;
		display: block;
		@media ( max-width: @screen-sm-max) {
			font-size: 24px;
		}
	}
	span{
		font-size: 14px;
		font-family: @font-family-serif;
		color: #c4c4c4;
		font-weight: 400;
		@media ( max-width: @screen-sm-max) {
			font-size: 12px;
		}
	}
}
.c-map__popup-table-item{
	@media ( max-width: @screen-sm-max) {
		padding-bottom: 10px;
		padding-top: 10px;
		border-bottom: 1px solid #D2D2D2;
	}
	&:not(:last-child){
		margin-bottom: 15px;
		@media ( max-width: @screen-sm-max) {
			margin-bottom: 0;
		}
	}
	.key{
		color: #084558;
		font-size: 14px;
		text-transform: uppercase;
		font-weight: 600;
		display: inline-block;
		text-align: right;
		width: 90px !important;
		margin-right: 14px;
		@media ( max-width: @screen-sm-max) {
			text-align: left;
			font-size: 12px;
		}
	}
	.value{
		display: inline-block;
		font-size: 14px;
		font-weight: 300;
		color: grey;
		@media ( max-width: @screen-sm-max) {
			font-size: 12px;
		}
		a{
			color: @green;
			text-decoration: underline;
		}
	}
}
.c-map__popup-txt{
	padding-top: 25px;
	padding-bottom: 25px;
	padding-left: 34px;
	padding-right: 34px;
	margin-bottom: 20px;
	background-color: #fff;
	@media ( max-width: @screen-sm-max) {
		padding-left: 25px;
		padding-right: 25px;
	    display: flex;
    	flex-direction: column-reverse;
	}
}

.fix_g_maps_styles{
	width: auto !important;
    margin-top: 30px;
    margin-left: 10px;
	div:not(.c-map__popup){
		width: auto !important;
		overflow: hidden !important;
		.key{
			width: 90px !important;
		}
		.close-mobile-popup{
			width: 30px !important;
			height: 30px !important;
			cursor: pointer;
		}
	}
	div[style="width: 0px; height: 0px; border-right: 10px solid transparent; border-left: 10px solid transparent; border-top: 24px solid rgba(0, 0, 0, 0.1); position: absolute; left: 300px; top: 533px;"]{
		display: none;
	}
}
.c-map__popup-mobile{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 333;
    width: 100%;
    background: #fff;
    height: 100vh;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease;
    &.active{
    	opacity: 1;
    	pointer-events: all;
    }
}
.close-mobile-popup{
	cursor: pointer;
	pointer-events: all;
	width: 37px;
	height: 37px;
	background-color: #084458;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 3;
	&:before, &:after{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 20px;
		height: 1px;
		background-color: #fff;
		display: block;
	}
	&:before{
		transform: rotate(45deg);
	}
	&:after{
		transform: rotate(-45deg);
	}
}
.map-main{
	position: relative;
}
.c-map-back-button{
	color: #fff;
	background-color: #0a4558;
	height: 62px;
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	line-height: 62px;
	&:hover{
		color: #fff;
		.c-map-back-button__icon{
			background-color: @green;
		}
	}
}
.c-map-back-button__icon{
	float: left;
	background-color: #093b4b;
	padding: 0 21px;
	transition: all 0.3s ease;
	.icon{
		width: 20px;
		height: 16px;
	    position: relative;
    	top: 4px;
	}
}
.c-map-back-button__txt{
	float: left;
	font-size: 18px;
	// font-weight: 500;
	text-align: center;
	padding: 0 28px;
	text-transform: uppercase;
	font-family: @font-family-serif;
}
.back-from-map-mob{
	display: block;
	position: absolute;
	top: 18px;
	right: 18px;
	z-index: 2;
	pointer-events: all;

	.icon{
		width: 20px;
		height: 16px;
		color: #fff;
	}
}
.c-map-sidebar__heading{
	@media ( max-width: @screen-sm-max) {
		position: relative;
		pointer-events: all;
	}
}
.inner-icon {
	position: absolute;
	z-index: 2;
	height: 40px;
	width: 40px !important;
	background: #fff; 
	bottom: 15px;
	padding: 6px 10px;
	transition: all 0.3s ease;
	cursor: pointer;
	background: #f1f1f1;
	&:hover {
		background: #093b4b;
	}
	@media ( max-width: @screen-sm-max) {
		position: static;
		margin-top: 20px;
		margin-right: 10px;
	}
}
.inner-icon:hover .icon {
	fill: #fff;
}
.inner-icon .icon {
	transition: all 0.3s ease;
	fill: #093b4b;
	width: 20px;
    height: 20px;
}
.inner-icon.left {
	right: 65px;
}
.inner-icon.right {
	right: 15px;
	transform: rotate(180deg);
}
